<template>
  <v-card>
    <v-toolbar :color="mainColor" dark flat>
      <v-toolbar-title>パスワードリセット</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form @submit.prevent="doSubmit">
        <v-text-field v-model="username" label="ユーザー名" />
        <v-text-field v-if="sent" v-model="code" label="確認コード" />
        <v-text-field
          v-if="sent"
          v-model="newPassword"
          label="新しいパスワード ※8文字以上、小・大文字を含む英数字、記号"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
        />
      </v-form>
      <v-alert v-show="message !== ''" :type="messageType" outlined>{{
        message
      }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn to="/auth/sign-in" text>戻る</v-btn>
      <v-btn v-if="!sent" :color="mainColor" text @click="doPasswordReset"
        >送信</v-btn
      >
      <v-btn v-else :color="mainColor" text @click="doVerify">リセット</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Auth, I18n } from 'aws-amplify'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'AuthForgotPassword',
  data() {
    return {
      mainColor: MAIN_COLOR,
      username: '',
      code: '',
      newPassword: '',
      message: '',
      messageType: 'info',
      sent: false,
      showNewPassword: false
    }
  },
  methods: {
    async doPasswordReset() {
      this.message = ''
      try {
        await Auth.forgotPassword(this.username)
        this.sent = true
        this.message = '確認コードを送信しました'
        this.messageType = 'success'
      } catch (e) {
        this.handleError(e)
      }
    },
    async doVerify() {
      this.message = ''
      try {
        await Auth.forgotPasswordSubmit(
          this.username,
          this.code,
          this.newPassword
        )
        alert('パスワードを変更しました')
        this.$router.push('/auth/sign-in')
      } catch (e) {
        this.handleError(e)
      }
    },
    doSubmit() {
      if (this.sent) {
        this.doVerify()
      } else {
        this.doPasswordReset()
      }
    },
    handleError(e) {
      switch (e.name) {
        case 'AuthError':
        case 'CodeMismatchException':
        case 'InvalidParameterException':
          this.message = I18n.get(e.message)
          this.messageType = 'error'
          break
        default:
          console.error(e)
          this.message = I18n.get(e.message)
          this.messageType = 'error'
          break
      }
    }
  }
}
</script>
